<template>
  <div class="plan-container">
    <empty :title="$t('MY_PROFILE.NO_PLANS')" />
  </div>
</template>

<script>
import Empty from "@/components/Shared/Empty";
export default {
  components: {
    Empty,
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
